import Vue from 'vue';
/**
 * 只允许输入合法的数字
 */
Vue.directive('inputDecimal', el => {
  el.querySelector('input').addEventListener('keyup', e => {
    if (isNaN(e.target.value)) {
      e.target.value = e.target.value.replace(/^(\d+.[^.]*).*$/, '$1');
      e.target.value = e.target.value.replace(/[^\d.]/g, '');
    }
    el.dispatchEvent(new Event('input'));
  });
});
