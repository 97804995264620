'use strict';

/**
 * 左侧主菜单
 */
export default [
  // 用户管理
  {
    path: '/user',
    component: () => import('../pages/user_manager'),
    name: 'User',
    meta: {
      name: 'User',
      title: '用户管理', // fc9aa3e8c401a950e88e1f1c4b919913
      icon: 'el-icon-folder',
      children: true
    },
    children: [
      {
        path: '/userList',
        component: () => import('../pages/user_manager/user'),
        name: 'User',
        meta: {
          name: 'User',
          title: '用户列表',//68d782b344b8ca0dd8428989f8a4eb9c
          icon: 'el-icon-document'
        },
        children: [
          {
            path: '/',
            name: 'User',
            component: () => import('../pages/user_manager/user/List'),
            meta: {
              name: 'User',
              title: '列表'
            }
          },
          {
            path: 'detail',
            name: 'UserDetail',
            component: () => import('../pages/user_manager/user/Detail'),
            meta: {
              name: 'UserDetail',
              title: '用户详情'
            }
          }
        ]
      }
    ]
  },
  // 场地管理
  {
    path: '/area',
    component: () => import('../pages/area_manager'),
    name: 'Area',
    meta: {
      name: 'Area',
      title: '场地管理',//6a622631f494482ae619e3c5a9afb673
      icon: 'el-icon-folder',
      children: true
    },
    children: [
      {
        path: '/venue',
        name: 'Area',
        component: () => import('../pages/area_manager/venue'),
        meta: {
          name: 'Area',
          title: '场地列表',//7b4b75657287d91c25fd9942a7c8bea8
          icon: 'el-icon-document'
        },
        children: [
          {
            path: '/',
            name: 'Area',
            component: () => import('../pages/area_manager/venue/List'),
            meta: {
              name: 'Area',
              title: '场地列表'
            }
          },
          {
            path: 'edit',
            name: 'VenueEdit',
            component: () => import('../pages/area_manager/venue/Edit'),
            meta: {
              name: 'VenueEdit',
              title: '编辑/新增场地'
            }
          },
          {
            path: 'reserve',
            name: 'VenueRreserve',
            component: () => import('../pages/area_manager/venue/Reserve'),
            meta: {
              name: 'VenueRreserve',
              title: '预定管理'
            }
          }
        ]
      },
      {
        path: '/order',
        name: 'Order',
        component: () => import('../pages/area_manager/order'),
        meta: {
          name: 'Order',
          title: '预约管理',//38ef7f29d2227f65672d26f5cd3bfac3
          icon: 'el-icon-document'
        },
        children: [
          {
            path: '/',
            name: 'Order',
            component: () => import('../pages/area_manager/order/List'),
            meta: {
              name: 'Order',
              title: '预约列表'
            }
          },
          {
            path: 'detail',
            name: 'OrderDetail',
            component: () => import('../pages/area_manager/order/Detail'),
            meta: {
              name: 'OrderDetail',
              title: '预约详情'
            }
          }
        ]
      },

      {
        path: '/place',
        name: 'Place',
        component: () => import('../pages/area_manager/place'),
        meta: {
          name: 'Place',
          title: '场地管理',
          icon: 'el-icon-document'
        },
        children: [
          {
            path: '/',
            name: 'Place',
            component: () => import('../pages/area_manager/place/List'),
            meta: {
              name: 'Place',
              title: '场地管理面板'
            }
          }
        ]
      },
      {
        path: '/month',
        name: 'Month',
        component: () => import('../pages/area_manager/month'),
        meta: {
          name: 'Month',
          title: '月结编号',
          icon: 'el-icon-document'
        },
        children: [
          {
            path: '/',
            name: 'Month',
            component: () => import('../pages/area_manager/month/List'),
            meta: {
              name: 'Month',
              title: '月结编号列表'
            }
          }
        ]
      },
    ]
  },
  // 活动管理
  {
    path: '/activities',
    component: () => import('../pages/activities_manager'),
    name: 'Activities',
    meta: {
      name: 'Activities',
      title: '活动管理',
      icon: 'el-icon-folder',
      children: true
    },
    children: [
      {
        path: '/Activities',
        name: 'Activities',
        component: () => import('../pages/activities_manager'),
        meta: {
          name: 'Activities',
          title: '活动列表',
          icon: 'el-icon-document'
        },
        children: [
          {
            path: '/Activities',
            name: 'Activities',
            component: () => import('../pages/activities_manager/activities/List'),
            meta: {
              name: 'activities',
              title: '活动列表'

            }
          },
          {
            path: 'copy',
            name: 'ActivitiesCopy',
            component: () => import('../pages/activities_manager/activities/Copy'),
            meta: {
              name: 'ActivitiesCopy',
              title: '活动复制'
            }
          },
          {
            path: 'edit',
            name: 'ActivitiesEdit',
            component: () => import('../pages/activities_manager/activities/Edit'),
            meta: {
              name: 'ActivitiesEdit',
              title: '活动编辑'
            }
          },
        ]
      },
      {
        path: '/apply',
        name: 'Apply',
        component: () => import('../pages/activities_manager/apply'),
        meta: {
          name: 'Apply',
          title: '活动报名管理',
          icon: 'el-icon-document'
        },
        children: [
          {
            path: '/',
            name: 'Apply',
            component: () => import('../pages/activities_manager/apply/List'),
            meta: {
              name: 'Apply',
              title: '活动报名管理'
            }
          },
          {
            path: 'approval',
            name: 'ApplyApproval',
            component: () => import('../pages/activities_manager/apply/Approval'),
            meta: {
              name: 'ApplyApproval',
              title: '批量审批'
            }
          },
          {
            path: 'detail',
            name: 'ApplyDetail',
            component: () => import('../pages/activities_manager/apply/Detail'),
            meta: {
              name: 'ApplyDetail',
              title: '报名详情'
            }
          }
        ]
      },
      {
        path: '/sign',
        name: 'Sign',
        component: () => import('../pages/activities_manager/sign'),
        meta: {
          name: 'Sign',
          title: '活动签到',
          icon: 'el-icon-document'
        },
        children: [
          {
            path: '/',
            name: 'Sign',
            component: () => import('../pages/activities_manager/sign/List'),
            meta: {
              name: 'Sign',
              title: '活动签到列表'
            }
          },
          {
            path: 'detail',
            name: 'SignDetail',
            component: () => import('../pages/activities_manager/sign/Detail'),
            meta: {
              name: 'SignDetail',
              title: '签到详情'
            }
          }
        ]
      },

    ]
  },
  // 内容管理
  {
    path: '/content',
    component: () => import('../pages/content_manager'),
    name: 'Content',
    meta: {
      name: 'Content',
      title: '内容管理',
      icon: 'el-icon-folder',
      children: true
    },
    children: [
      {
        path: '/Content',
        name: 'Content',
        component: () => import('../pages/content_manager/introduction/List'),
        meta: {
          name: 'Content',
          title: '项目介绍',
          icon: 'el-icon-document'
        },
      },
      {
        path: '/dynamic',
        name: 'Dynamic',
        component: () => import('../pages/content_manager/dynamic'),
        meta: {
          name: 'Dynamic',
          title: '动态播报',
          icon: 'el-icon-document'
        },
        children: [
          {
            path: '/Dynamic',
            name: 'Dynamic',
            component: () => import('../pages/content_manager/dynamic/List'),
            meta: {
              name: 'Dynamic',
              title: '动态播报列表'
            }
          },
          {
            path: 'Edit',
            name: 'DynamicEdit',
            component: () => import('../pages/content_manager/dynamic/Edit'),
            meta: {
              name: 'DynamicEdit',
              title: '新增文章'
            }
          }
        ]
      },
      {
        path: '/privacy',
        component: () => import('../pages/content_manager/Privacy/List'),
        name: 'Privacy',
        meta: {
          name: 'Privacy',
          title: '场地预订须知',
          icon: 'el-icon-folder'
        }
      },
      {
        path: '/about',
        component: () => import('../pages/content_manager/about/List'),
        name: 'About',
        meta: {
          name: 'About',
          title: '关于我们',
          icon: 'el-icon-folder'
        }
      },
      {
        path: '/banner',
        name: 'Banner',
        component: () => import('../pages/content_manager/banner'),
        meta: {
          name: 'Banner',
          title: '轮播图管理',
          icon: 'el-icon-document'
        },
        children: [
          {
            path: '/Banner',
            name: 'Banner',
            component: () => import('../pages/content_manager/banner/List'),
            meta: {
              name: 'Banner',
              title: '列表'
            }
          },
          {
            path: 'Edit',
            name: 'BannerEdit',
            component: () => import('../pages/content_manager/banner/Edit'),
            meta: {
              name: 'BannerEdit',
              title: '新增轮播图'
            }
          }
        ]
      },

    ]
  },
  // 财务管理
  {
    path: '/finance',
    component: () => import('../pages/finance_manager'),
    name: 'Finance',
    meta: {
      name: 'Finance',
      title: '财务管理',
      icon: 'el-icon-folder',
      children: true
    },
    children: [
      {
        path: '/Finance',
        component: () => import('../pages/finance_manager/List'),
        name: 'Finance',
        meta: {
          name: 'Finance',
          title: '平台流水',
          icon: 'el-icon-document'
        }
      },
      {
        path: 'FinanceInvoice',
        component: () => import('../pages/finance_manager/Invoice'),
        name: 'FinanceInvoice',
        meta: {
          name: 'FinanceInvoice',
          title: '发票管理',
          icon: 'el-icon-document'
        }
      }
    ]
  },
  // 权限管理
  {
    path: '/permission',
    component: () => import('../pages/permission_manager'),
    name: 'Permission',
    meta: {
      name: 'Permission',
      title: '权限管理',
      icon: 'el-icon-folder',
      children: true
    },
    children: [
      {
        path: '/permission',
        component: () => import('../pages/permission_manager/admin/List'),
        name: 'Permission',
        meta: {
          name: 'Permission',
          title: '管理员列表',
          icon: 'el-icon-document'
        },
      },
      {
        path: '/Role',
        component: () => import('../pages/permission_manager/role/List'),
        name: 'Role',
        meta: {
          name: 'Role',
          title: '角色管理',
          icon: 'el-icon-document'
        },
      },
      {
        path: '/Log',
        component: () => import('../pages/permission_manager/log/List'),
        name: 'Log',
        meta: {
          name: 'Log',
          title: '操作日志',
          icon: 'el-icon-document'
        }
      }
    ]
  },
];
