<template>
  <el-button
    :size="size"
    :icon="icon"
    :type="type"
    v-if="$store.getters.ACCESS_STATE(access)"
    @click="$emit('click')"
    ><slot></slot
  ></el-button>
</template>
<script>
export default {
  name: "YqlAccessButton",
  props: {
    size: {
      type: String,
      default: "medium",
    },
    icon: String,
    type: {
      type: String,
      default: "default",
    },
    access: {
      type: String,
      required: true,
    },
  },
};
</script>
