<template>
  <textarea class="yql-editor" ref="editor" :placeholder="placeholder"></textarea>
</template>
<script>
import _ from 'lodash';
export default {
  name: 'YqlEditor',
  model: {
    prop: 'content',
    event: 'change'
  },
  props: {
    content: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      editor: {},
      watchState: true,
      changes: {}
    };
  },
  watch: {
    content(nv, ov) {
      if (this.watchState) {
        this.editor.setData(nv);
      }
      for (let change of this.changes) {
        if (change.name === 'imageBlock' || change.attributeKey === 'uploadStatus') {
          if (change.type === 'remove' || change.attributeNewValue === 'reading' || change.attributeOldValue === 'complete') {
            let oImgs = this.getImages(ov);
            let nImgs = this.getImages(nv);
            let resource = _.difference(oImgs, nImgs);
            if (resource.length) {
              // this.$request.post('/api/cms/resourceRemove', { resource });
            }
          } else if (change.type === 'insert') {
            let oImgs = this.getImages(ov);
            let nImgs = this.getImages(nv);
            let resource = _.difference(nImgs, oImgs);
            if (resource.length) {
              // this.$request.post('/api/cms/resourceRevoke', { resource });
            }
          }
        }
      }
    }
  },
  async mounted() {
    document.getElementsByClassName('ck-editor')[0]?.remove();
    this.editor = await this.$ckeditor(this.$refs.editor);
    this.editor.model.document.on('change:data', this.changeHandler);
    this.editor.set('content', this.editor.getData());
    this.editor.model.document.on('change:content', this.changeHandler);
  },
  methods: {
    /**
     * 文档发生更改事件处理函数
     */
    changeHandler() {
      this.changes = [];
      this.watchState = false;
      this.changes = this.editor.model.document.differ.getChanges();
      this.$emit('change', this.editor.getData());
    },
    /**
     * 获取内容中的所有图片地址
     */
    getImages(data) {
      const div = document.createElement('div');
      div.innerHTML = data;
      let images = div.querySelectorAll('img');
      images = [...images];
      images = images.map(image => image.getAttribute('src'));
      images = images.filter(item => item);
      return images;
    }
  }
};
</script>
<style lang="scss">
.ck-content {
  min-height: 300px !important;
  border-radius: 0 0 5px 5px !important;
  border-color: #e4e7ec !important;
  line-height: 1.5em;
}

.ck-toolbar {
  border-color: #e4e7ec !important;
  border-radius: 5px 5px 0 0 !important;
}
</style>
