<template>
  <el-tree :data="data" :node-key="nodeKey" :show-checkbox="showCheckbox" :default-expand-all="defaultExpandAll"
    :expand-on-click-node="expandOnClickNode" :check-on-click-node="checkOnClickNode" :check-strictly="checkStrictly"
    :default-checked-keys="defaultCheckedKeys" :props="props" @check-change="checkChange" ref="tree"></el-tree>
</template>
<script>
export default {
  model: {
    prop: 'keys',
    event: 'change'
  },
  props: {
    keys: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      required: true
    },
    nodeKey: {
      type: String,
      default: '_id'
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    defaultExpandAll: {
      type: Boolean,
      default: false
    },
    expandOnClickNode: {
      type: Boolean,
      default: false
    },
    checkOnClickNode: {
      type: Boolean,
      default: false
    },
    checkStrictly: {
      type: Boolean,
      default: false
    },
    props: {
      type: Object,
      default() { return {} }
    }
  },
  computed: {
    defaultCheckedKeys() {
      return this.keys;
    }
  },
  methods: {
    checkChange() {
      const tree = this.$refs['tree']
      this.$emit('change', tree.getCheckedKeys());
    }
  }
}
</script>