<template>
  <div class="yql-map-input">
    <section v-for="(item, index) in attrs" :key="index">
      <el-input class="input-name" :placeholder="placeholder.name" v-model="item.name" @change="nameChangeHandler">
      </el-input>
      <el-input class="input-value" :placeholder="placeholder.value" v-model="item.value"
        @change="valueChangeHandler(item)"></el-input>
      <el-button class="del-btn" @click="delBtnClickHandler(index)" icon="el-icon-delete" type="text" plain circle>
      </el-button>
    </section>
    <el-button type="text" icon="el-icon-plus" @click="addClickHandler" v-show="hideAddBtn">新增</el-button>
  </div>
</template>

<script>
export default {
  name: 'YqlMapInput',
  model: {
    prop: 'data',
    event: 'change'
  },
  props: {
    data: {
      type: Array,
      required: false,
      default: () => []
    },
    placeholder: {
      type: Object,
      require: false,
      default() {
        return { name: '请输入属性名', value: '请输入属性值' };
      }
    }
  },
  data() {
    return {
      attrs: []
    };
  },
  watch: {
    data(value) {
      this.attrs = [...value];
    }
  },
  computed: {
    hideAddBtn() {
      return !this.attrs.filter(item => !item.name).length;
    }
  },
  methods: {
    // 新增按钮点击事件处理
    addClickHandler() {
      if (!this.attrs.filter(item => !item.name).length) {
        this.attrs.push({});
      }
    },
    // key值更改事件处理
    nameChangeHandler() {
      this.$emit('change', this.attrs.filter(item => item.name));
    },
    // value值更改事件处理
    valueChangeHandler(item) {
      if (item.name) {
        this.$emit('change', [...this.attrs]);
      }
    },
    // 删除
    delBtnClickHandler(index) {
      this.attrs.splice(index, 1);
      this.$emit('change', [...this.attrs]);
    }
  }
};
</script>

<style lang="scss" scoped>
.yql-map-input {
  width: 100%;

  section {
    display: flex;
    margin-bottom: 5px;

    .input-name {
      flex: 0 0 200px;
    }

    .input-value {
      flex: 1;
      margin: 0 5px;
    }

    .del-btn {
      flex: 0 0;
    }
  }
}
</style>
