export default {
  // 设置面包屑导航
  SET_BREADCRUMB_LIST(state, payload) {
    state.breadcrumb_list = payload;
  },
  // 设置激活的菜单项
  SET_ACTIVE_MENU(state, payload) {
    state.acitve_menu = payload;
  },
  // 设置用户授权信息
  SET_AUTH_USER(state, payload) {
    state.auth_user = { ...state.auth_user, ...payload };
  },
  SET_MENU(state, payload) {
    state.menu = { ...state.menu, ...payload };
  }

}