<template>
  <div>
    <el-upload :headers="headers" :accept="accept" class="yql-image-upload" :style="{ height, width, 'background-image': `url(${backgroundImage})` }" :action="action || $store.getters.UPLOAD_URL" :show-file-list="false" list-type="picture" :on-preview="preview" :on-remove="remove" :on-error="error" :on-success="success" :before-upload="beforeUpload" :multiple="false">
      <i v-if="!src" class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>
<script>
/**
 * 图片上传组件
 */
export default {
  name: 'YqlImageUpload',
  model: {
    prop: 'src',
    event: 'change'
  },
  props: {
    // 图片上传路径
    action: {
      type: String,
      required: false
    },
    // 图片路径
    src: {
      type: String,
      required: false,
      default: ''
    },
    width: {
      type: String,
      required: false,
      default: '148px'
    },
    height: {
      type: String,
      required: false,
      default: '148px'
    },
    data: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      accept: '.jpg,.jpeg,.gif,.png,.webp',
      allowType: ['image/gif', 'image/png', 'image/jpeg', 'image/bmp', 'image/webp'],
      headers: {
        'x-access-token': this.$store.getters.AUTH_USER_TOKEN
      }
    };
  },
  computed: {
    backgroundImage() {
      console.log('this', this.src);
      if ((this.src && this.src.indexOf(this.$store.getters.OSS_BASE_URL) > -1) || this.src.indexOf('https://') > -1) {
        return this.src;
      }
      return `${this.$store.getters.OSS_BASE_URL}${this.src}`;
    }
  },
  methods: {
    success(response) {
      this.$emit('change', response.result.data);
    },
    remove(file, fileList) {
      this.$emit(
        'change',
        fileList.map(item => item.url)
      );
    },
    preview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      this.$emit('preview', file.url);
    },
    beforeUpload(file) {
      if (this.allowType.find(item => item == file.type)) {
        return true;
      } else {
        this.$message.error('文件类型错误');
        return false;
      }
    },
    error(err) {
      this.$message.error(JSON.parse(err.message).error);
    }
  }
};
</script>

<style lang="scss" scoped>
.yql-image-upload {
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  display: flex;

  &:hover {
    border-color: #409eff;
  }

  :deep(.el-upload) {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .avatar {
    width: auto;
    height: 100%;
    display: block;
  }
}
</style>
