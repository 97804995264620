// CKEditor自定义上传适配器
import Vue from "vue";
import request from "./axios";
import store from "@/store";

/**
 * 上传适配器
 */
class UploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append('file', file);
          request.post(store.getters.UPLOAD_PATH, formData)
            .then(res => {
              resolve({ default: `${store.getters.OSS_BASE_URL}${res.data}` });
            })
            .catch(err => {
              reject('');
            });
        })
    );
  }
}

/**
 * 配置上传适配器插件
 */
function UploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = loader => new UploadAdapter(loader);
}

/**
 * 创建编辑器对象
 */
const $ckeditor = dom =>
  new Promise((resolve, reject) => {
    ClassicEditor.create(dom, {
      language: 'zh-cn',
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        removeItems: ['fontFamily'],
        shouldNotGroupWhenFull: true
      }
    })
      .then(editor => {
        resolve(editor);
      })
      .catch(error => {
        reject(error);
      });
  });

Vue.prototype.$ckeditor = $ckeditor;
