'use strict';

import Vue from 'vue';
import 'element-ui/lib/theme-chalk/index.css';

import { InputNumber, Notification, Badge, Aside, Avatar, Breadcrumb, BreadcrumbItem, Button, ButtonGroup, Card, Cascader, CascaderPanel, Checkbox, CheckboxButton, CheckboxGroup, Col, Container, DatePicker, TimePicker, Descriptions, DescriptionsItem, Dialog, Upload, Form, FormItem, Header, Link, Image, Input, Main, Menu, Message, MessageBox, MenuItem, Option, PageHeader, Pagination, Popconfirm, Popover, Progress, Radio, Row, Select, Submenu, Switch, Table, TableColumn, Tabs, TabPane, Tag, Tooltip, Tree } from 'element-ui';

Vue.use(Aside);
Vue.use(Avatar);
Vue.use(Badge);
Vue.use(ButtonGroup);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Button);
Vue.use(Card);
Vue.use(Cascader);
Vue.use(CascaderPanel);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Col);
Vue.use(Container);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(Dialog);
Vue.use(Upload);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Header);
Vue.use(Link);
Vue.use(Image);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(Row);
Vue.use(Main);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Option);
Vue.use(PageHeader);
Vue.use(Pagination);
Vue.use(Popconfirm);
Vue.use(Popover);
Vue.use(Progress);
Vue.use(Select);
Vue.use(Submenu);
Vue.use(Switch);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tag);
Vue.use(Tooltip);
Vue.use(Tree);

Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$notify = Notification;
