<template>
  <el-descriptions :title="title" :column="column">
    <template v-for="(item, index) in items">
      <el-descriptions-item v-if="item.label" :key="index" :label="item.label" :span="item.span || 1">
        <slot :name="item.name" :item="item" :text="item | getPropData(data)" v-if="item.prop">{{ item | getPropData(data) }}</slot>
        <slot :name="item.name" :item="item" :text="item" v-else>{{ item.text }}</slot>
      </el-descriptions-item>
    </template>
  </el-descriptions>
</template>

<script>
export default {
  /**
   * @prop title 标题
   * @prop column 列数
   * @prop data 数据
   *  格式：{name:'数据标识',label:'标签',text:'值',span:'占用列的数量',}
   */
  props: {
    title: String,
    column: {
      type: Number,
      default: 1
    },
    items: {
      type: Array,
      default: () => []
    },
    data: Object
  },
  filters: {
    getPropData(item, data) {
      try {
        return eval(`data.${item.prop}`) || item.text || '';
      } catch {
        return item.text || '';
      }
    }
  }
};
</script>

<style lang='scss' scoped >
.el-descriptions {
  :deep(.el-descriptions-item__label) {
    color: #999;
  }
  :deep(.el-descriptions-item__container) {
    align-items: center;

    .el-descriptions-item__content {
      flex: 1;
    }
  }
}
</style>
</style>