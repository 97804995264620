<template>
  <span>{{ value | formate(formate) }}</span>
</template>
<script>
export default {
  name: 'YqlBreadcrumb',
  props: {
    value: {
      type: String,
      required: true
    },
    formate: {
      type: String,
      required: false,
      default: 'yyyy-MM-dd HH:mm:ss'
    }
  },
  filters: {
    formate(value, fmt) {
      let date = new Date(value);

      let ret;
      const opt = {
        'y+': date.getFullYear().toString(), // 年
        'M+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'm+': date.getMinutes().toString(), // 分
        's+': date.getSeconds().toString() // 秒
      };
      for (let k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt);
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'));
        }
      }

      return fmt;
    }
  }
};
</script>
