<template>
  <el-card class="panel" :style="`--bgcolor:${bgColor};--textcolor: ${textColor}`">
    <el-progress type="dashboard" :percentage="percentage" :format="format" :color="color"></el-progress>
    <h4>{{ title }}</h4>
  </el-card>
</template>
<script>
export default {
  name: 'YqlDataPanel',
  props: {
    bgColor: {
      type: String,
      default: '#fff'
    },
    textColor: {
      type: String,
      default: '#303133'
    },
    color: {
      type: String,
      default: '#20a0ff'
    },
    title: {
      type: String,
      required: true
    },
    percentage: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: '%'
    }
  },
  methods: {
    format() {
      return this.label;
    }
  }
};
</script>
<style lang="scss" scoped>
.panel {
  color: var(--textcolor);
  text-align: center;
  background: var(--bgcolor);

  h4 {
    font-weight: 400;
    font-size: 16px;
  }

  :deep(.el-progress__text) {
    color: var(--textcolor);
  }
}
</style>
