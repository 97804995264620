import request from '@/assets/plugins/axios';

export default {
  // 授权登录
  async AUTH_LOING(store, payload = {}) {
    console.log('store', store);
    let authUser = await request.post('cms/AdminLogin', payload);
    const auth = {
      token: authUser.data.access_token
    };
    sessionStorage.setItem('AUTH_USER', JSON.stringify(auth));
    store.commit('SET_AUTH_USER', auth);
  },

  //  场地列表
  async VENUE_LIST(store, payload = {}) {
    return await request.get('cms/Places', payload);
  },
  //  场地列表新增
  async VENUE_ADD(store, payload = {}) {
    return await request.post('cms/Places', payload);
  },
  //  场地列表详情
  async VENUE_SHOW(store, payload = {}) {
    return await request.get(`cms/Places/${payload}`, {});
  },

  //  场地列表更新
  async VENUE_UPDATE(store, payload) {
    return await request.put(`cms/Places`, payload);
  },
  //  场地列表删除
  async VENUE_DELETE(store, payload = {}) {
    return await request.delete(`cms/Places/${payload}`, {});
  },
  //  场馆预定管理
  async VENUE_ORDER(store, payload = {}) {
    if (payload.export == 1) {
      payload.filename = '预约管理.xlsx';
      return await request.download('cms/OrderExport', payload);
    }
    return await request.get(`cms/Order`, payload);
  },
  //  场馆预定编辑
  async VENUE_ORDER_DETAIL(store, payload = {}) {
    return await request.get(`cms/Order/${payload}`, {});
  },

  //  月结列表
  async MONTH_SETTLEMENT_LIST(store, payload = {}) {
    return await request.get(`cms/PayNumbers`, payload);
  },
  //  月结列表-删除
  async MONTH_SETTLEMENT_REMOVE(store, payload = {}) {
    return await request.delete(`cms/PayNumbers/${payload}`, {});
  },
  //  月结列表-新增
  async MONTH_SETTLEMENT_INSERT(store, payload = {}) {
    return await request.post(`cms/PayNumbers`, payload);
  },

  //  轮播图列表
  async BANNER_LIST(store, payload = {}) {
    return await request.get(`cms/Banner`, payload);
  },
  //  轮播图列表-新增
  async BANNER_INSERT(store, payload = {}) {
    return await request.post(`cms/Banner`, payload);
  },
  //  轮播图列表-编辑
  async BANNER_UPDATE(store, payload) {
    return await request.put(`cms/Banner`, payload);
  },

  //  轮播图列表-详情
  async BANNER_DETAIL(store, payload = {}) {
    return await request.get(`cms/Banner/${payload}`, {});
  },

  //  轮播图列表-删除
  async BANNER_REMOVE(store, payload = {}) {
    return await request.delete(`cms/Banner/${payload}`, {});
  },

  //  动态播报列表
  async ACTICLES_LIST(store, payload = {}) {
    return await request.get(`cms/Articles`, payload);
  },
  //  动态播报列表-新增
  async ACTICLES_INSERT(store, payload = {}) {
    return await request.post(`cms/Articles`, payload);
  },
  //  动态播报列表-详情
  async ACTICLES_DETAIL(store, payload = {}) {
    return await request.get(`cms/Articles/${payload}`, payload);
  },
  //  动态播报列表-更新
  async ACTICLES_UPDATE(store, payload) {
    return await request.put(`cms/Articles`, payload);
  },
  //  动态播报列表-删除
  async ACTICLES_REMOVE(store, payload = {}) {
    return await request.delete(`cms/Articles/${payload}`, {});
  },

  //  操作日志列表
  async ADMINLOGS_LIST(store, payload = {}) {
    return await request.get(`cms/AdminLogs`, payload);
  },
  // 平台流水
  async JOURNAL_ACCOUNT_LIST(store, payload = {}) {
    if (payload.export == 1) {
      payload.filename = '平台流水.xlsx';
      return await request.download('cms/SystemBillExport', payload);
    }
    return await request.get(`cms/SystemBill`, payload);
  },
  // 发票管理
  async INVOICE_LIST(store, payload = {}) {
    if (payload.export == 1) {
      payload.filename = '平台流水.xlsx';
      return await request.download('cms/InvoiceExport', payload);
    }
    return await request.get(`cms/Invoice`, payload);
  },
  // 活动列表
  async ACTIVITY_LIST(store, payload = {}) {
    return await request.get(`cms/Activity`, payload);
  },
  // 活动删除
  async ACTIVITY_DELETE(store, payload = {}) {
    return await request.delete(`cms/Activity/${payload}`, {});
  },
  // 活动详情
  async ACTIVITY_DETAIL(store, payload = {}) {
    return await request.get(`cms/Activity/${payload}`, {});
  },
  // 活动新增
  async ACTIVITY_INSERT(store, payload) {
    return await request.post(`cms/Activity`, payload);
  },
  // 活动更新
  async ACTIVITY_UPDATE(store, payload) {
    return await request.put(`cms/Activity`, payload);
  },
  // 活动报名列表
  async SIGNS_LIST(store, payload = {}) {
    if (payload.export == 1) {
      payload.filename = '活动报名.xlsx';
      return await request.download('cms/SignsExport', payload);
    }
    return await request.get(`cms/Signs`, payload);
  },
  // 活动报名删除
  async SIGNS_DELETE(store, payload = {}) {
    return await request.delete(`cms/Signs`, payload);
  },
  // 活动报名详情
  async SIGNS_DETAIL(store, payload = {}) {
    return await request.get(`cms/Signs/${payload}`, payload);
  },
  // 活动报名新增
  async SIGNS_INSERT(store, payload = {}) {
    return await request.post(`cms/Signs`, payload);
  },
  // 活动报名更新
  async SIGNS_UPDATE(store, payload = {}) {
    return await request.put(`cms/Signs`, payload);
  },

  // 用户列表
  async USER_LIST(store, payload = {}) {
    if (payload.export == 1) {
      payload.filename = '用户列表.xlsx';
      return await request.download('cms/UserExport', payload);
    }
    return await request.get('cms/User', payload);
  },

  // 用户列表-用户详情
  async USER_SHOW(store, payload = {}) {
    return await request.get(`cms/User/${payload}`, {});
  },

  // 用户列表-用户更新
  async USER_UPDATE(store, payload = {}) {
    return await request.put(`cms/User`, payload);
  },

  // // 用户列表-公司名称历史填写记录
  // async USER_CHANGE_RECORD(store, payload = {}) {
  //   return await request.get('cms/UserChangeRecord', { user_uuid: payload });
  // },

  // 权限管理

  // 管理员管理 列表
  async ADMIN_LIST(store, payload = {}) {
    return await request.get('cms/Admin', payload);
  },

  // 管理员管理 详情
  async ADMIN_DETAIL(store, payload = {}) {
    return await request.get(`cms/Admin/${payload}`, {});
  },

  // 管理员管理 新增
  async ADMIN_INSERT(store, payload = {}) {
    return await request.post(`cms/Admin`, payload);
  },

  // 管理员管理 更新
  async ADMIN_UPDATE(store, payload = {}) {
    return await request.put(`cms/Admin`, payload);
  },

  // 管理员管理 删除
  async ADMIN_DELETE(store, payload = {}) {
    return await request.delete(`cms/Admin/${payload}`, {});
  },

  // 角色管理 列表
  async ROLE_LIST(store, payload) {
    return await request.get('cms/Role', payload);
  },

  // 角色管理 详情
  async ROLE_DETAIL(store, payload = {}) {
    return await request.get(`cms/Role/${payload}`, {});
  },

  // 角色管理 新增
  async ROLE_INSERT(store, payload = {}) {
    return await request.post(`cms/Role`, {});
  },

  // 角色管理 更新
  async ROLE_UPDATE(store, payload = {}) {
    return await request.put(`cms/Role`, payload);
  },

  // 角色管理 删除
  async ROLE_DELETE(store, payload = {}) {
    return await request.delete(`cms/Role/${payload}`, {});
  },

  // 菜单管理 列表
  async MENU_LIST(store, payload) {
    const data = await request.get('cms/AdminLogin', payload);
    console.log('datadatadatadatadatadata', data.data);
    sessionStorage.setItem('MENU_LIST', JSON.stringify(data.data.role.menus));
    store.commit('SET_MENU', data.data);
    return data;
  },
  //  订单管理 列表
  async ORDER_LIST(store, payload) {
    return await request.get('cms/Order', payload);
  },
  // 消息列表
  async MESSAGE_LIST(store, payload = {}) {
    return await request.get(`cms/Notify`, payload);
  },
  // 关闭消息提示
  async MESSAGE_CLOSE(store, payload = {}) {
    return await request.put(`cms/Notify/${payload}`, {});
  },
  // 删除消息
  async MESSAGE_REMOVE(store, payload = {}) {
    return await request.delete(`cms/Notify/${payload}`, {});
  }
};
