export default {
  /**
    * 获取某个授权项的授权状态
    * @param name 授权项名称
    */
  ACCESS_STATE: state => name => state.auth_user && state.auth_user.access ? state.auth_user.access.find(item => item == name) : false,
  // 获取面包屑导航
  BREADCRUMB_LIST: state => state.breadcrumb_list.filter((item, index) => index > 0),
  // 获取激活菜单
  ACTIVE_MENU: state => state.acitve_menu,
  // 获取授权用户的昵称
  AUTH_USER_NICKNAME: state => state.auth_user.nickname,
  // 获取收取用户的头像
  AUTH_USER_AVATAR: state => state.auth_user.avatar,
  // 获取授权用户的TOKEN
  AUTH_USER_TOKEN: state => state.auth_user.token,
  // 获取订单状态
  ORDER_STATE: state => index => state.order_state[index],
  // 获取支付状态
  PAY_STATE: state => index => state.pay_state[index],
  // 获取支付类型
  PAY_TYPE: state => index => state.pay_type[index],
  // 获取预定状态
  RESERVE_STATE: state => index => state.reserve_state[index],
  // 获取订单类型
  JOURNAL_TYPE: state => index => state.journal_type[index],
  // 获取资源路径的URL
  OSS_BASE_URL: state => state.oss_base_url,
  // 获取资源路径的URL
  API_BASE_URL: state => state.api_base_url,
  // 获取上传文件的API路径
  UPLOAD_PATH: state => state.upload_path,
  // 获取上传文件的API路径
  UPLOAD_URL: state => `${state.api_base_url}${state.upload_path}`,




  // // 获取下架状态
  // STATUS_DICT: state => index => state.status_dict[index],
}