<template>
  <div class="row g-3 m-0 p-3 pt-0" :class="`row-cols-${cols}`">
    <div class="col" v-for="(item, index) in data" :key="index" @click="onClick(item,index)">
      <div class="inner py-1 text-nowrap" :class="{ selected: item.selected == 1, active: item.state == 2, disabled: item.state == 3 }">{{ item.text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  // model: {
  //   prop: 'data',
  //   event: 'change'
  // },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    cols: {
      type: Number,
      default: 6
    }
  },
  methods: {
    onClick(item,index) {
      // if (item.selected === 0 && item.state != 2) {
      //   item.selected = 1;
      // } else if (item.selected === 1) {
      //   item.selected = 0;
      // }
      this.$emit('change', item,index);
    }
  }
};
</script>

<style lang='scss' scoped>
.row {
  font-size: 14px;
  .col {
    cursor: pointer;
    text-align: center;
    .inner {
      border: 1px solid #e0e0e0;
      background: #fff;
      &.active {
        background: #f2b667;
      }
      &.selected {
        border: 1px solid #108ee9;
      }
      &.disabled {
        background: #b6b6b6;
      }
    }
  }
}
</style>