<template>
  <div class="identify" @click="refresh">
    <canvas id="identify" :width="contentWidth" :height="contentHeight"></canvas>
  </div>
</template>
<script>
/**
 * @prop {Number} fontSizeMin 文字的最小尺寸
 * @prop {Number} fontSizeMax 文字的最大尺寸
 * @prop {Number} backgroundColorMin 背景颜色的最小数值
 * @prop {Number} backgroundColorMax 背景颜色的最大数值
 * @prop {Number} colorMin 文字颜色的最小数值
 * @prop {Number} colorMax 文字颜色的最大数值
 * @prop {Number} lineColorMin 干扰线颜色的最小数值
 * @prop {Number} lineColorMax 干扰线颜色的最大数值
 * @prop {Number} dotColorMin 干扰点颜色的最小数值
 * @prop {Number} dotColorMax 干扰点颜色的最大数值
 * @prop {Number} contentWidth 内容宽度
 * @prop {Number} contentHeight 内容高度
 * @prop {Number} contentSize 内容长度
 */
export default {
  name: 'YqlIdentify',
  props: {
    fontSizeMin: {
      type: Number,
      default: 30
    },
    fontSizeMax: {
      type: Number,
      default: 33
    },
    backgroundColorMin: {
      type: Number,
      default: 180
    },
    backgroundColorMax: {
      type: Number,
      default: 240
    },
    colorMin: {
      type: Number,
      default: 50
    },
    colorMax: {
      type: Number,
      default: 160
    },
    lineColorMin: {
      type: Number,
      default: 100
    },
    lineColorMax: {
      type: Number,
      default: 200
    },
    dotColorMin: {
      type: Number,
      default: 0
    },
    dotColorMax: {
      type: Number,
      default: 255
    },
    contentWidth: {
      type: Number,
      default: 100
    },
    contentHeight: {
      type: Number,
      default: 30
    },
    contentSize: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      identifyCode: '',
      identifyCodes: '123456789abcdefghijklmnpqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ'
    };
  },
  methods: {
    // 生成一个随机数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 生成一个随机的颜色
    randomColor(min, max) {
      let r = this.randomNum(min, max);
      let g = this.randomNum(min, max);
      let b = this.randomNum(min, max);
      return 'rgb(' + r + ',' + g + ',' + b + ')';
    },
    transparent() {
      return 'rgb(255,255,255)';
    },
    drawPic() {
      let canvas = document.getElementById('identify');
      let ctx = canvas.getContext('2d');
      ctx.textBaseline = 'bottom';
      // 绘制背景
      ctx.fillStyle = this.randomColor(this.backgroundColorMin, this.backgroundColorMax);
      //图形填充颜色设置
      ctx.strokeStyle = this.randomColor(this.backgroundColorMin, this.backgroundColorMax);
      //图形轮廓的颜色设置
      ctx.fillRect(0, 0, this.contentWidth, this.contentHeight); //绘制一个填充的矩形 0 0 width height x起点 y起点  宽 高
      ctx.strokeRect(0, 0, this.contentWidth, this.contentHeight); // 绘制一个矩形边框 0 0 width height x起点 y起点  宽 高
      ctx.clearRect(0, 0, this.contentWidth, this.contentHeight); //清除指定矩形区域，清除部分完全透明
      // 绘制文字
      for (let i = 0; i < this.identifyCode.length; i++) {
        this.drawText(ctx, this.identifyCode[i], i);
      }
      this.drawLine(ctx);
      this.drawDot(ctx);
    },
    drawText(ctx, txt, i) {
      ctx.fillStyle = this.randomColor(this.colorMin, this.colorMax);
      ctx.font = this.randomNum(this.fontSizeMin, this.fontSizeMax) + 'px SimHei';
      let x = (i + 1) * (this.contentWidth / (this.identifyCode.length + 1));
      let y = this.randomNum(this.fontSizeMax, this.contentHeight - 5);
      var deg = this.randomNum(-10, 10);
      // 修改坐标原点和旋转角度
      ctx.translate(x, y);
      ctx.rotate((deg * Math.PI) / 180);
      ctx.fillText(txt, 0, 0);
      // 恢复坐标原点和旋转角度
      ctx.rotate((-deg * Math.PI) / 180);
      ctx.translate(-x, -y);
    },
    drawLine(ctx) {
      // 绘制干扰线
      for (let i = 0; i < 3; i++) {
        ctx.strokeStyle = this.randomColor(this.lineColorMin, this.lineColorMax);
        ctx.beginPath();
        ctx.moveTo(this.randomNum(0, this.contentWidth), this.randomNum(0, this.contentHeight));
        ctx.lineTo(this.randomNum(0, this.contentWidth), this.randomNum(0, this.contentHeight));
        ctx.stroke();
      }
    },
    drawDot(ctx) {
      // 绘制干扰点
      for (let i = 0; i < 60; i++) {
        ctx.fillStyle = this.randomColor(0, 255);
        ctx.beginPath();
        ctx.arc(this.randomNum(0, this.contentWidth), this.randomNum(0, this.contentHeight), 1, 0, 2 * Math.PI);
        ctx.fill();
      }
    },
    /**
     * 刷新验证码
     */
    refresh() {
      this.identifyCode = '';
      for (let i = 0; i < this.contentSize; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length - 1)];
      }
      this.drawPic();
    },
    /**
     * 获取验证码
     */
    getCode() {
      return this.identifyCode;
    }
  },
  watch: {
    identifyCode() {
      this.drawPic();
    }
  },
  mounted() {
    this.refresh();
  }
};
</script>

<style lang="scss" scoped>
.identify {
  cursor: pointer;
}
</style>