<template>
  <el-image :src="url" fit="contain" :preview-src-list="[url]"></el-image>
</template>

<script>
export default {
  name: 'YqlImage',
  props: {
    src: String
  },
  computed: {
    url() {
      if (!this.src) return '';
      return this.src.startsWith('http') ? this.src : `${this.$store.getters.OSS_BASE_URL}${this.src}`;
    }
  }
};
</script>