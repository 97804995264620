export default {
  // OSS资源路径
  oss_base_url: 'https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/',

  // API接口路径
  // api_base_url: 'https://jrpt-api.vanke.com/v1/',
  api_base_url: 'https://xmh-service-api.gymooit.cn/v1/',

  // 文件上传路径
  upload_path: 'cms/UploadFile',
  // 面包屑导航
  breadcrumb_list: [],
  // 激活的菜单
  menu: '',
  // 授权用户
  auth_user: {},
  // 订单状态
  order_state: ['待支付', '待审核', '待开始', '进行中', '已完成', '已取消'],
  // 支付状态
  pay_state: ['', '未支付', '已支付'],
  // 支付类型
  pay_type: ['', '在线支付', '月结支付'],
  // 预定状态
  reserve_state: ['', '空闲', '已预约', '已锁定'],
  // 订单类型
  journal_type: ['', '订单预定', '订单退订'],
  // // 下架状态
  // status_dict: ['不显示', '显示'],
  // 请求提示
  requestShow: true
};
