'use strict';
import Vue from 'vue';
import axios from 'axios';
import { Message, Loading } from 'element-ui';
import store from '@/store';

var loadingInstance;

function showLoading() {
  loadingInstance = Loading.service({
    background: 'transparent',
    text: '加载中...',
    spinner: 'el-icon-loading'
  });
}

function closeLoading() {
  if (loadingInstance) {
    loadingInstance.close();
    loadingInstance = undefined;
  }
}

// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    if (store.requestShow) {
      showLoading();
    }
    store.requestShow = true;
    config.baseURL = store.getters.API_BASE_URL;
    config.timeout = 15000;
    config.retry = 3;
    config.retryDelay = 1000;
    return config;
  },
  function (error) {
    Message.error(error.message);
    return Promise.reject(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  function (response) {
    closeLoading();
    if (response.data.code !== '2000' && response.config.responseType !== 'blob') {
      Message.error(response.data?.msg);
      return Promise.reject(response.data);
    }
    return response.data.result || response;
  },
  function (error) {
    closeLoading();
    console.log(error);
    if (!error.response) {
      Message.error('请求超时');
    } else {
      if (error.response.status == 401 || error.response.status == 403) {
        Message.error({
          message: error.response.data.error.message,
          onClose() {
            location = '/#/login';
          }
        });
      } else if (error.response.status == 400 || error.response.status == 500) {
        Message.error(error.response.data.error.message);
      } else if (error.response.status == 404) {
        Message.error('数据请求失败');
      } else {
        Message.error('数据请求异常');
      }
    }

    return Promise.reject('error');
  }
);

const request = {
  post(url, json = {}) {
    return axios.post(url, json, {
      headers: { 'x-access-token': store.getters.AUTH_USER_TOKEN }
    });
  },
  put(url, json = {}) {
    return axios.put(url, json, {
      headers: { 'x-access-token': store.getters.AUTH_USER_TOKEN }
    });
  },
  get(url, params = {}) {
    return axios.get(url, {
      headers: { 'x-access-token': store.getters.AUTH_USER_TOKEN },
      params
    });
  },
  delete(url) {
    return axios.delete(url, {
      headers: { 'x-access-token': store.getters.AUTH_USER_TOKEN }
    });
  },
  download(url, params = {}) {
    axios
      .get(url, {
        headers: { 'x-access-token': store.getters.AUTH_USER_TOKEN },
        params
      })
      .then(res => {
        const url = res.data;

        // const fileName = params.filename;
        // const elink = document.createElement('a');
        // elink.download = fileName;
        // elink.style.display = 'none';
        // document.body.appendChild(elink);
        // elink.href = `https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/${url}`;
        // elink.click();
        // URL.revokeObjectURL(elink.href);
        // document.body.removeChild(elink);
        // return;

        axios
          .get(`https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/${url}`, {
            responseType: 'blob'
          })
          .then(res => {
            const blob = new Blob([res.data]);
            const fileName = params.filename;
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          });
        // .catch(e => {
        //   console.error(e);
        //   Message.error('下载失败');
        // });
      })
      .catch(e => {
        console.error(e);
        Message.error('下载失败');
      });
  }
};

Vue.prototype.$request = request;

export default request;
