<template>
  <div>
    <el-upload :class="{ hide: limit === images.length }" :limit="limit" ref="upload" :accept="accept" :action="action || $store.getters.UPLOAD_URL" :file-list="fileList" list-type="picture-card" :on-preview="preview" :on-remove="remove" :on-success="success" :on-error="error" :before-upload="beforeUpload" :headers="headers" :multiple="multiple">
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog v-if="dialogImageUrl" :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'YqlImageListUpload',
  model: {
    prop: 'images',
    event: 'change'
  },
  props: {
    // 图片上传路径
    action: {
      type: String,
      required: false
    },
    // 图片路径
    images: {
      type: Array,
      required: false,
      default: () => []
    },
    // 限制上传文件的个数
    limit: {
      type: Number,
      required: false,
      default: 100
    },
    // 默认多选模式
    multiple: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    fileList() {
      return this.images.map((item, index) => {
        let uid = this.fileListSuccess[index] ? this.fileListSuccess[index].uid : '';
        const url = item.data || item;
        item = `${url.indexOf(this.$store.getters.OSS_BASE_URL) > -1 ? '' : this.$store.getters.OSS_BASE_URL}${url}`;
        return { url: item, uid: uid || index + 1 };
      });
    }
  },
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      headers: {
        'x-access-token': this.$store.getters.AUTH_USER_TOKEN
      },
      fileListSuccess: [],
      accept: '.jpg,.jpeg,.gif,.png,.webp',
      allowType: ['image/gif', 'image/png', 'image/jpeg', 'image/bmp', 'image/webp']
    };
  },
  methods: {
    success(response, file, fileList) {
      this.fileListSuccess = fileList;
      console.log('fileList', JSON.parse(JSON.stringify(fileList)));
      this.$emit(
        'change',
        fileList.map(item => (item.response ? item.response.result.data : item.url.replace(this.$store.getters.OSS_BASE_URL, '')))
      );
    },
    remove(file, fileList) {
      this.$emit(
        'change',
        fileList.map(item => item.url)
      );
    },
    preview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      this.$emit('preview', file.url);
    },
    beforeUpload(file) {
      if (this.allowType.find(item => item == file.type)) {
        return true;
      } else {
        this.$message.error('文件类型错误');
        return false;
      }
    },
    error(err) {
      this.$message.error(JSON.parse(err.message).error);
    }
  }
};
</script>
<style lang="scss" scoped>
.hide {
  :deep(.el-upload--picture-card) {
    display: none !important;
  }
}
</style>