<template>
  <el-menu unique-opened :default-active="defaultActive || menus[0].meta.name" @select="menuSelectHandler" :mode="model" :background-color="bgColor" :text-color="color" :active-text-color="activeColor" :collapse-transition="false" :collapse="collapse">
    <template v-for="menu in menus">
      <template v-if="!accessValidate || (accessValidate && $store.getters.ACCESS_STATE(menu.meta.name))">
        <el-submenu v-if="menu.meta.children && menu.children && menu.children.length" :key="menu.meta.name" :index="menu.meta.name">
          <template slot="title">
            <el-avatar v-if="menu.meta.src" :src="menu.meta.src" size="small"></el-avatar>
            <i v-if="menu.meta.icon" :class="menu.meta.icon"></i>
            <span slot="title">{{ menu.meta.title }}</span>
          </template>
          <template v-for="subMenu in menu.children">
            <el-menu-item :index="subMenu.meta.name" :key="subMenu.meta.name" v-if="!accessValidate || (accessValidate && $store.getters.ACCESS_STATE(subMenu.meta.name))">
              <i v-if="subMenu.meta.icon" :class="subMenu.meta.icon"></i>
              <span slot="title">{{ subMenu.meta.title }}</span>
            </el-menu-item>
          </template>
        </el-submenu>
        <el-menu-item :index="menu.meta.name" v-else :key="menu.meta.name">
          <i v-if="menu.meta.icon" :class="menu.meta.icon"></i>
          <span slot="title">{{ menu.meta.title }}</span>
        </el-menu-item>
      </template>
    </template>
  </el-menu>
</template>

<script>
export default {
  name: 'YqlMenu',
  props: {
    /**
     * 背景颜色
     */
    bgColor: {
      type: String,
      default: '#212529'
    },
    /**
     * 前景色
     */
    color: {
      type: String,
      default: '#fff'
    },
    /**
     * 激活前景色
     */
    activeColor: {
      type: String,
      default: '#fff'
    },
    /**
     * 模式
     * vertical=垂直模式(默认值)
     * horizontal=水平模式
     */
    model: {
      type: String,
      default: 'vertical'
    },
    /**
     * 是否水平折叠，仅在model=vertical时可用
     * true=折叠
     * false=不折叠
     */
    collapse: {
      type: Boolean,
      default: false
    },
    /**
     * 菜单数据
     */
    menus: {
      type: Array,
      required: true
    },
    /**
     * 开启授权校验
     * true=开启(默认)
     * false=不开启
     */
    accessValidate: {
      type: Boolean,
      default: true
    },
    defaultActive: String
  },
  methods: {
    menuSelectHandler(index) {
      this.$emit('select', index);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-menu {
  border: none;
  .el-avatar {
    margin-right: 10px;
  }
}
</style>
