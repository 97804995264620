import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import AsideMenusRouter from './asideMenus';
import TopMenusRouter from './topMenus';
import { uniqWith } from 'lodash';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
Vue.use(VueRouter);

const flat = (data, name = 'childs') => {
  const arr = [];
  const flatFn = data => {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (item[name]?.length) {
        flatFn(item[name]);
      }
      arr.push(item);
    }
  };
  flatFn(data);
  return arr;
};
const gather = (data, storeMenu = []) => {
  const gatherFn = (data, n = 0) => {
    let b = [];
    let number = n + 1;
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (number <= 2) {
        const index = storeMenu.findIndex(storeMenuItem => {
          return storeMenuItem.name.trim() == item?.meta?.title && storeMenuItem.url == item.path;
        });
        if (index > -1) {
          if (!item.children?.length) {
            b.push(item);
          } else {
            b.push({ ...item, children: gatherFn(item.children, number) });
          }
        }
      } else {
        b.push(item);
      }
    }
    return b;
  };
  return gatherFn(data);
};
export const getRoutes = () => {
  const menu_storage = sessionStorage.getItem('MENU_LIST') || undefined;
  // if (!menu_storage) return undefined;
  const menuObject = JSON.parse(menu_storage || '[]');
  let menu = [];
  Object.keys(menuObject).map(item => menu.push(menuObject[item]));
  menu = uniqWith(flat(menu), (a, b) => a.name === b.name && a.url == b.url);
  const asideMenu = gather(AsideMenusRouter, menu);
  console.log('asideMenu', JSON.parse(JSON.stringify(asideMenu)));
  return {
    path: '/',
    name: 'home',
    component: () => import('../pages/Index'),
    children: [...asideMenu, ...TopMenusRouter]
    // children: [...AsideMenusRouter, ...TopMenusRouter]
  };
};

export const router = new VueRouter({
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('../pages/Login')
    },
    getRoutes()
  ]
});

router.beforeEach((route, redirec, next) => {
  // 持久化授权用户信息
  const authUser = sessionStorage.getItem('AUTH_USER') || undefined;
  const menu = sessionStorage.getItem('MENU_LIST') || undefined;
  if (authUser) {
    store.commit('SET_AUTH_USER', JSON.parse(authUser));
  }
  if (menu && !store.state.menu) {
    store.commit('SET_MENU', JSON.parse(menu));
  }
  if (route.name != 'Login' && !store.getters.AUTH_USER_TOKEN) {
    next({ name: 'Login' });
  } else {
    // 设置网页标题
    let title = '';
    let matched = [...route.matched];
    matched.reverse().forEach((item, index) => {
      if (index != route.matched.length - 1) {
        title += item.meta.title + '_';
      }
    });

    document.title = title + '后台管理';

    // 设置面包屑导航
    store.commit('SET_BREADCRUMB_LIST', route.matched);
    // 设置激活菜单项
    store.commit('SET_ACTIVE_MENU', route.matched.length < 2 ? route.meta.name : route.matched[route.matched.length - 1].parent.name || route.matched[route.matched.length - 1].name);
    next();
  }
});

export default router;
