<template>
  <el-table @selection-change="handleSelectionChange" :data="data" row-key="_id" lazy :load="load" ref="table">
    <el-table-column type="selection" :selectable="selectable" v-if="selectedVisible" :width="selectedWidth" :label="selectedLabel"></el-table-column>
    <el-table-column align="center" v-if="sortVisible" type="index" :width="sortWidth" :label="sortLabel"></el-table-column>
    <el-table-column align="center" show-overflow-tooltip v-for="(item, index) in columns" :key="index" :prop="item.prop" :label="item.label" :fixed="item.fixed" :width="item.width">
      <template slot-scope="scope">
        <slot :name="item.prop" :row="scope.row">{{ scope.row[item.prop] }}</slot>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  name: 'YqlTable',
  data() {
    return {};
  },
  props: {
    data: {
      type: Array,
      required: false,
      default: () => []
    },
    columns: {
      type: Array,
      required: false,
      default: () => []
    },
    sortLabel: {
      type: String,
      required: false,
      default: ''
    },
    sortVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    sortWidth: {
      type: String,
      required: false,
      default: '50px'
    },
    selectedLabel: {
      type: String,
      required: false,
      default: ''
    },
    selectedVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedWidth: {
      type: String,
      required: false,
      default: '50px'
    },
    load: {
      type: Function,
      required: false,
      default: () => {
        return null;
      }
    },
    selectable: {
      type: Function,
      default: () => {
        return true;
      }
    }
  },
  methods: {
    getTable() {
      return this.$refs.table;
    },
    handleSelectionChange(val) {
      this.$emit('changeSelected', val);
    }
  }
};
</script>
<style lang="scss" scoped>
.el-table {
  width: 100%;
}
</style>
