<template>
  <el-form :model="formData" :inline="inline" :size="size" class="yql-form" ref="elForm" :rules="rules">
    <template v-for="(field, index) in fields">
      <el-form-item :label="field.label" :label-width="labelWidth" v-show="!field.hidden" :key="index" :prop="field.name" :rules="field.rules">
        <slot :name="field.name" :field="formData[field.name]" :row="field">
          <!-- 单行文本输入框 -->
          <template v-if="field.type === 'text'">
            <el-input v-model="formData[field.name]" clearable :placeholder="field.placeholder" :disabled="field.disabled" show-word-limit>
              <template v-if="field.append" slot="append">{{ field.append }}</template>
              <template v-if="field.prepend" slot="prepend">{{ field.prepend }}</template>
            </el-input>
          </template>
          <!-- 密码输入框 -->
          <template v-if="field.type === 'password'">
            <el-input type="password" v-model="formData[field.name]" clearable :placeholder="field.placeholder" :disabled="field.disabled" show-password></el-input>
          </template>
          <!-- 文本域 -->
          <template v-else-if="field.type === 'textarea'">
            <el-input :maxlength="field.maxlength" v-model="formData[field.name]" :placeholder="field.placeholder" :disabled="field.disabled" type="textarea" :rows="6" show-word-limit></el-input>
          </template>
          <!-- 下拉选择框 -->
          <template v-else-if="field.type === 'select'">
            <el-select clearable :multiple="field.multiple" :allow-create="field.allowCreate" :default-first-option="field.defaultFirstOption" :filterable="field.filterable" :placeholder="field.placeholder" v-model="formData[field.name]" @change="$emit(`${field.name}Change`)" :disabled="field.disabled">
              <el-option v-for="option in payload[field.payload]" :key="option[field.config && field.config.value ? field.config.value : 'id']" :label="option[field.config && field.config.label ? field.config.label : 'name']" :value="option[field.config && field.config.value ? field.config.value : 'id']"></el-option>
            </el-select>
          </template>
          <!-- 级联选择框 -->
          <template v-else-if="field.type === 'cascader'">
            <el-cascader v-model="formData[field.name]" :placeholder="field.placeholder" filterable :options="payload[field.payload]" clearable :props="{ value: 'id', label: 'name', emitPath: false, ...field.props }"></el-cascader>
          </template>
          <!-- 级联选择面板 -->
          <template v-else-if="field.type === 'cascaderPanel'">
            <el-cascader-panel v-model="formData[field.name]" :placeholder="field.placeholder" filterable :options="payload[field.payload]" clearable :props="{ value: 'id', label: 'name', emitPath: false, ...field.props }"></el-cascader-panel>
          </template>
          <!-- 普通按钮 -->
          <template v-else-if="field.type === 'button'">
            <el-button :type="field.theme" @click="field.click">{{ field.text }}</el-button>
          </template>
          <!-- 按钮组 -->
          <template v-else-if="field.type === 'btnGroup'">
            <el-button :key="item.type" v-for="item in field.btns" :type="item.theme" @click="item.click">{{ item.text }}</el-button>
          </template>
          <!-- 日期选择框 -->
          <template v-else-if="field.type === 'date'">
            <el-date-picker :picker-options="field.options" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" :placeholder="field.placeholder" clearable v-model="formData[field.name]"></el-date-picker>
          </template>
          <!-- 日期选择框 -->
          <template v-else-if="field.type === 'inputNumber'">
            <el-input-number v-model="formData[field.name]" :min="1" :max="100000"></el-input-number>
          </template>
          <!-- 开关 -->
          <template v-else-if="field.type === 'switch'">
            <el-switch v-model="formData[field.name]" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
          </template>
          <!-- 富文本编辑器 -->
          <template v-else-if="field.type === 'editor'">
            <yql-editor v-model="formData[field.name]" :placeholder="field.placeholder"></yql-editor>
          </template>
          <!-- 图片列表上传 -->
          <template v-else-if="field.type === 'imageListUpload'">
            <yql-image-list-upload v-model="formData[field.name]" :limit="field.limit"></yql-image-list-upload>
          </template>
          <!-- 图片上传 -->
          <template v-else-if="field.type === 'imageUpload'">
            <yql-image-upload v-model="formData[field.name]" :limit="field.limit"></yql-image-upload>
          </template>
          <!-- 单选按钮 -->
          <template v-else-if="field.type === 'radio'">
            <el-radio v-model="formData[field.name]" v-for="radio in payload[field.payload]" :key="radio.id" :label="radio.id">
              {{ radio.name }}
            </el-radio>
          </template>

          <!-- 多选按钮 -->
          <template v-else-if="field.type === 'checkbox'">
            <el-checkbox-group v-model="formData[field.name]">
              <el-checkbox v-for="checkbox in payload[field.payload]" :name="checkbox.name" :label="checkbox.id" :key="checkbox.id">
                {{ checkbox.name }}
              </el-checkbox>
            </el-checkbox-group>
          </template>
          <!-- 键值对输入框 -->
          <template v-else-if="field.type === 'mapInput'">
            <yql-map-input v-model="formData[field.name]" :placeholder="field.placeholder"></yql-map-input>
          </template>
          <!-- Tree -->
          <template v-else-if="field.type === 'tree'">
            <yql-tree v-model="formData[field.name]" :data="payload[field.payload]" :showCheckbox="field.showCheckbox" :defaultExpandAll="field.defaultExpandAll" :expandOnClickNode="field.expandOnClickNode" :checkOnClickNode="field.checkOnClickNode" :checkStrictly="field.checkStrictly" :defaultCheckedKeys="field.defaultCheckedKeys" :nodeKey="field.nodeKey" :props="{ label: 'name', children: 'childs' }"></yql-tree>
          </template>
          <!-- 日期范围 -->
          <template v-else-if="field.type === 'rangeDate'">
            <el-date-picker format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="formData[field.name]" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </template>
        </slot>
      </el-form-item>
    </template>
  </el-form>
</template>
<script>
export default {
  name: 'YqlForm',
  model: {
    prop: 'formData',
    event: 'submit'
  },
  data() {
    return {
      services_uuid: []
    };
  },
  props: {
    // 表单数据
    formData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    // 表单字段配置
    fields: {
      type: Array,
      required: true
    },
    // Label宽度
    labelWidth: {
      type: String,
      default: '100px'
    },
    // true:行模式 false:列模式
    inline: Boolean,
    // 表单尺寸
    size: {
      type: String,
      default: 'medium'
    },
    // 负载数据
    payload: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 延迟加载处理函数
    lazyload: {
      type: Function,
      default: () => {
        return [];
      }
    },
    rules: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  created() {
    // let formUUID = sessionStorage.getItem('FORM_UUID');
    // if (!formUUID) {
    //   sessionStorage.setItem('FORM_UUID', this.$uuid.v4());
    // }
  },
  watch: {
    'formData.id'(nv) {
      if (nv) {
        this.$request.put(`/api/cms/resourceInit/${nv}`);
      }
    }
  },
  methods: {
    validata() {
      let result = true;
      this.$refs['elForm'].validate(valid => {
        result = valid;
      });

      return result;
    },
    reset() {
      this.$refs['elForm'].resetFields();
    }
  }
};
</script>
<style lang="scss" scoped>
.el-cascader,
.el-select,
.el-date-editor {
  width: 100%;
}
</style>
