'use strict';

import Vue from 'vue';
import './element-ui';
import './ckeditor';

import YqlAccessButton from '@/components/yql/element/YqlAccessButton/YqlAccessButton';
import YqlAmapInput from '@/components/yql/element/YqlAmapInput/YqlAmapInput';
import YqlCheckboxButtonGroup from '@/components/yql/element/YqlCheckboxButtonGroup/YqlCheckboxButtonGroup';
import YqlDataPanel from '@/components/yql/element/YqlDataPanel/YqlDataPanel';
import YqlDateTime from '@/components/yql/element/YqlDateTime/YqlDateTime';
import YqlDescriptions from '@/components/yql/element/YqlDescriptions/YqlDescriptions';
import YqlEditor from '@/components/yql/element/YqlEditor/YqlEditor';
import YqlIdentify from '@/components/yql/element/YqlIdentify/YqlIdentify';
import YqlImage from '@/components/yql/element/YqlImage/YqlImage';
import YqlImageListUpload from '@/components/yql/element/YqlImageListUpload/YqlImageListUpload';
import YqlImageUpload from '@/components/yql/element/YqlImageUpload/YqlImageUpload';
import YqlForm from '@/components/yql/element/YqlForm/YqlForm';
import YqlMapInput from '@/components/yql/element/YqlMapInput/YqlMapInput';
import YqlMenu from '@/components/yql/element/YqlMenu/YqlMenu';
import YqlTable from '@/components/yql/element/YqlTable/YqlTable';
import YqlTipButton from '@/components/yql/element/YqlTipButton/YqlTipButton';
import YqlTree from '@/components/yql/element/YqlTree/YqlTree';

Vue.component('YqlAccessButton', YqlAccessButton);
Vue.component('YqlAmapInput', YqlAmapInput);
Vue.component('YqlCheckboxButtonGroup', YqlCheckboxButtonGroup);
Vue.component('YqlDataPanel', YqlDataPanel);
Vue.component('YqlDateTime', YqlDateTime);
Vue.component('YqlDescriptions', YqlDescriptions);
Vue.component('YqlEditor', YqlEditor);
Vue.component('YqlIdentify', YqlIdentify);
Vue.component('YqlImage', YqlImage);
Vue.component('YqlImageListUpload', YqlImageListUpload);
Vue.component('YqlImageUpload', YqlImageUpload);
Vue.component('YqlForm', YqlForm);
Vue.component('YqlMapInput', YqlMapInput);
Vue.component('YqlMenu', YqlMenu);
Vue.component('YqlTable', YqlTable);
Vue.component('YqlTipButton', YqlTipButton);
Vue.component('YqlTree', YqlTree);
