<template>
  <el-tooltip v-if="$store.getters.ACCESS_STATE(access) && is_show" effect="dark" :content="tip" placement="top" :enterable="false">
    <el-button @click="$emit('click')" :icon="icon" size="mini" :type="type" v-if="label">{{ label }}</el-button>
    <el-button @click="$emit('click')" :icon="icon" size="mini" :type="type" v-else></el-button>
  </el-tooltip>
</template>
<script>
export default {
  name: 'YqlTipButton',
  props: {
    icon: String,
    type: String,
    tip: String,
    label: String,
    access: String,
    is_show: {
      type: Boolean,
      default: true
    }
  }
};
</script>
