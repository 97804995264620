<template>
  <div class="yql-amap">
    <el-input placeholder="请输入或选择地址,例如:江苏省徐州市铜山区文化路1号" v-model="data.address" @input="addressInputHandler">
      <template slot="append">
        <el-checkbox v-model="showMap">地图</el-checkbox>
        <el-checkbox v-model="onlyMarker">自动解析</el-checkbox>
      </template>
    </el-input>
    <div class="ampa-box" v-show="showMap">
      <el-amap ref="addressMap" vid="address" :plugin="plugin" class="amap" :zoom="zoom" :center="data.location || [117.283752, 34.204224]" :events="mapEvents">
        <el-amap-marker v-for="(marker, index) in markers" :key="index" :ref="`marker${index}`" :position="marker" :events="markerEvents"></el-amap-marker>
      </el-amap>
    </div>
  </div>
</template>
<script>
export default {
  name: 'YqlAmapInput',
  model: {
    prop: 'data',
    event: 'choose'
  },
  props: {
    data: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      markers: [],
      mapEvents: { click: this.mapClickHandler },
      markerEvents: { click: this.markerClickHandler },
      showMap: true,
      zoom: 14,
      plugin: ['ToolBar'],
      onlyMarker: true
    };
  },
  watch: {
    'data.location': {
      handler(nv) {
        this.markers.push(nv);
      }
    }
  },
  methods: {
    // 点击地图
    mapClickHandler(e) {
      const { lng, lat } = e.lnglat;
      let lnglat = [lng, lat];

      const geocoder = new AMap.Geocoder({ city: 'all', radius: 50 });
      this.markers = [lnglat];

      if (!this.onlyMarker) {
        this.$emit('choose', {
          ...this.data,
          location: [lng, lat]
        });
        return;
      }

      geocoder.getAddress(lnglat, (status, result) => {
        if (status === 'complete' && result.regeocode) {
          let { province, city, district, township } = result.regeocode.addressComponent;
          this.data.address = result.regeocode.formattedAddress;
          this.$emit('choose', {
            location: [lng, lat],
            address: result.regeocode.formattedAddress,
            province,
            city,
            district,
            township
          });
        }
      });
    },
    // 地址更改事件
    addressInputHandler(e) {
      if (this.onlyMarker) {
        this.markers = [];
      }
      this.showMap = true;
      const geocoder = new AMap.Geocoder({ city: 'all', radius: 100 });
      geocoder.getLocation(this.data.address, (status, result) => {
        if (status === 'complete') {
          result.geocodes.forEach((item, index) => {
            let { lat, lng } = item.location;
            if (index == 0) {
              let { province, city, district, township } = item.addressComponent;
              this.$emit('choose', {
                location: [lng, lat],
                address: this.data.address,
                province: province,
                city,
                district,
                township
              });
            }
            if (this.onlyMarker) {
              this.markers.push([lng, lat]);
              this.center = [lng, lat];
            }
          });
        }
      });
    },
    // 点击点坐标
    markerClickHandler(e) {
      let { lng, lat } = e.lnglat;
      this.center = [lng, lat];
      if (this.onlyMarker) {
        const geocoder = new AMap.Geocoder({ city: 'all', radius: 100 });
        geocoder.getAddress(this.center, (status, result) => {
          if (status === 'complete' && result.regeocode) {
            this.data.address = result.regeocode.formattedAddress;
            let { province, city, district, township } = result.regeocode.addressComponent;
            this.$emit('choose', {
              location: [lng, lat],
              address: result.regeocode.formattedAddress,
              province,
              city,
              district,
              township
            });
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.yql-amap {
  .ampa-box {
    position: relative;
    .ampa-search-box {
      position: absolute;
      width: 250px;
      right: 10px;
      top: 10px;
      border: 1px solid #dcdfe6;
      box-shadow: none;
      border-radius: 4px;
      height: 36px;
      margin-bottom: 10px;
      box-sizing: border-box;
    }
    .amap {
      height: 400px;
      width: 100%;
    }
  }
}
</style>
